<template>
  <div class="mevcut-container">
    <HomeOffersGroupSlider :enable-cache="true" class="my-5" />
    <div>
    </div>
    <MaterialsGrid
      class="my-5"
      key-cache="offers-grid"
      :fetch-route="materialsRoute"
      :fetch-params="fetchParams"
      with-query-params
      @get-materials-count="setNewOffersNumber($event)"
    />
  </div>
</template>

<script setup lang="ts">
import getAllRoutes from '~/composables/AppApiRoutes'
const { url } = useDomainHost()
const { t } = useI18n()
const route = useRoute()

const { isAuthLoggedIn } = useMenuModel()
const isAuthenticated = isAuthLoggedIn()
const materialsRoute =
getAllRoutes(isAuthenticated).materialsRoutes.materialsByServer
const fetchParams = computed(() => {
  return { offersOnly: true, ...route.query }
})
const { setNewOffersNumber } = useOffersNumber()

useServerSeoMeta(
  useOgMeta(
    url + '/offers',
    t('offers_discounts'),
    t('mevcut_description'),
    url + '/images/mevcut.webp'
  )
)

useHead({
  title: t('offers_discounts'),
  link: [useSeoCanonical(url + '/offers')],
  script: [
    useSeoBreadcrumb([
      useSeoBreadcrumbItem(1, t('home'), url),
      useSeoBreadcrumbItem(2, t('offers_discounts'), url + '/offers')
    ])
  ]
})
</script>
